@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

//common color

.orange {
  color: #ff8f00;
}
.purple {
  color: #962490;
}
.bg-orange {
  background-color: #ff8f00;
  color: #ffffff;
  
}
.bg-purple {
  background-color: #962490;
  color: #ffffff;
  
}
.pe-bg {
  background: linear-gradient(to right, #e7a644d7, #811097c5);
}

//end comman color

//heading tags

.pt-5,
.py-5 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

h2 {
  color: #008080;
  font-weight: 700;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-transform: lowercase;
}

h2:after {
  content: "";
  width: 50px;
  height: 3px;
  background: #40e0d0;
  display: block;
  margin: 5px auto;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

h2:hover:after {
  width: 200px;
}

h3 {
  font-size: 1rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h4 {
  font-family: "Montserrat", sans-serif;
  background: transparent;
  text-transform: uppercase;
  font-weight: 800;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 0.2em;
  color: rgba(4, 51, 78, 0.7);
  border-radius: 0;
}
h4:after {
  content: "";
  width: 50px;
  height: 3px;
  background: #962490;
  display: block;
  margin: 5px auto;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

h4:hover:after {
  width: 150px;
}

.h5,
h5 {
  font-size: 1.25rem;
  padding-top: 10px;
}
//end heading tags
